"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Polyfills for IE. Important
require("./polyfills");
require("@babel/polyfill");
require('./../scss/sites/laitepalvelu/laitepalvelu.scss');
var index_1 = require("./../js/index");
// Initialize scripts that are used in all websites
index_1.Init();
// Import styles and scripts included in coreweb packages
index_1.ImportCoreweb();
// Initialize and mount react components
index_1.InitReact();
// Import site specific scripts
//require("./Sites/hartwall");
